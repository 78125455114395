import { ENDPOINTS } from '../constants/endpoints';
import axiosClient from './axiosClient.service';
import { axiosClientFile } from './axiosClient.service';
import { isNumber } from 'lodash';

export function getUserManagement(userPersonaId, status, sortType) {
  return axiosClient.get(ENDPOINTS.GET_USERMANAGEMENT_USERS + userPersonaId + (isNumber(status) ? `?status=${status}&sortType=${sortType}` : ''));
}

export function getOpus(userOpuId) {
  return axiosClient.get(ENDPOINTS.GET_USERMANAGEMENT_OPUS + userOpuId);
}

export function getPersonas() {
  return axiosClient.get(ENDPOINTS.GET_USERMANAGEMENT_PERSONAS);
}

export function getRoles() {
  return axiosClient.get(ENDPOINTS.GET_USERMANAGEMENT_ROLES);
}

export function updateUser(userId: any, data: any = {}) {
  return axiosClient.put(ENDPOINTS.PUT_USERMANAGEMENT_USER + userId, data);
}

export function deleteUser(userId: any) {
  return axiosClient.delete(ENDPOINTS.PUT_USERMANAGEMENT_USER + userId );
}

export function rejectUser(data: any) {
  return axiosClient.post(ENDPOINTS.POST_USERMANAGEMENT_REJECT_USER, data);
}

export function createUser(data: any = {}) {
  return axiosClient.post(ENDPOINTS.POST_USERMANAGEMENT_CREATE_USER, data);
}

export function getPersonaConfig(viewTypeId: any, userOpuId: any) {
  return axiosClient.get(
    ENDPOINTS.GET_USERMANAGEMENT_PERSONA_TABLE + `/${viewTypeId}` + userOpuId
  );
}

export function getWMTable(userOpuId: any) {
  return axiosClient.get(ENDPOINTS.GET_USERMANAGEMENT_WM_TABLE + userOpuId);
}

export function getAddMapList() {
  return axiosClient.get(ENDPOINTS.GET_USERMANAGEMENT_ADD_MAP_LIST);
}

export function updatePermission(data: any = {}) {
  return axiosClient.post(
    ENDPOINTS.POST_USERMANAGEMENT_UPDATE_PERMISSION + data.personaId,
    data
  );
}

export function getPersonaList(viewTypeId: any) {
  return axiosClient.get(
    ENDPOINTS.GET_USERMANAGEMENT_PERSONA_LIST + viewTypeId
  );
}

export function updatePersonaList(
  viewTypeId: any,
  personaId: any,
  updateData: any
) {
  return axiosClient.post(
    ENDPOINTS.POST_USERMANAGEMENT_PERSONA_LIST +
    `${viewTypeId}/viewsconfig/${personaId}`,
    updateData
  );
}

export function getUsersDownload() {
  return axiosClient.get(ENDPOINTS.GET_USERMANAGEMENT_DOWNLOAD);
}

export function getUserStatus() {
  return axiosClient.get(ENDPOINTS.GET_USERMANAGEMENT_STATUS);
}
