import { Action } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as types from '../action/actionType';
import { getMapData } from '../services/map.service';
import { RootState } from '../reducer/root.reducer';
interface MapAction extends Action {
  data: string | any;
}

function* fetchMapData(action: MapAction) {
  try {
    const selectedPoint = yield select((state: RootState) => state.map.selectedPoint);

    if (selectedPoint) {
      return;
    }

    const data = yield call(getMapData, action.data);
    yield put({ type: types.MAP_DATA_FETCH_SUCCEEDED, payload: data });
  } catch (error: any) {
    yield put({ type: types.MAP_DATA_FETCH_FAILED, message: error.message });
  }
}

export function* workerFetchMapData() {
  yield takeLatest(types.MAP_DATA_FETCH_REQUESTED, fetchMapData);
}