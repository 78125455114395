import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Collapse, Progress, Table } from 'antd';
import s from './FindingCAPDetail.module.css';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';

const FindingCAPDetail = () => {
  const { Panel } = Collapse;
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const assuranceReferenceNumberByYear = useSelector((state) =>
    get(state, 'risk.assuranceAssetCallout.result.assuranceReferenceNumberByYear', [])
  );

  const statusCount = ({ findingVal, capVal, overdueVal }: { overdueVal: number, findingVal: number, capVal: number }) => {
    return (
      <>
        <span className={s.statusCount}><span className={s.statusCountNo}>{findingVal}</span> Findings</span>
        <span className={s.statusCount}><span className={s.statusCountNo}>{capVal}</span> CAP</span>
        <span className={s.statusCount}><span className={s.statusCountNo}>{overdueVal}</span> Overdue</span>
      </>
    );
  };

  const renderCategoryCollapse = (category) => {
    if (category?.length === 0) {
      return <p>No data available for Overdue action item.</p>;
    }

    return (
      category.map((categoryItem, categoryIdx) => (
        <Collapse
          key={'categoryItem' + categoryIdx}
          className={s.childrenCollapse}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <img
              alt="arrow"
              src="/img/icon/accordion-arrow-down.svg"
              className={isActive ? s.arrowActive : s.arrow}
            />
          )}
        >
          <Panel
            header={
              <div className={s.childPanelHeader}>
                <span className={s.childPanelSubHeader}>
                  Category
                </span>
                <span className={s.checkListName}>
                  {categoryItem.category}
                </span>
              </div>
            }
            key={'categoryItem' + categoryIdx}
          >
            {categoryItem.findings.map((finding, findingIdx) => <div className={s.findingsContainer} key={'categoryItem' + categoryIdx + 'finding' + findingIdx}>
              <div className={s.findingBody}>
                <span className={s.childPanelSubHeader}>
                  #{findingIdx + 1} Finding
                </span>
                <span className={s.actionDescription}>{finding.finding}</span>
                {finding.caps?.map((cap, capIdx) => <div className={s.findingActionItemContainer} key={'categoryItem' + categoryIdx + 'finding' + findingIdx + "cap" + capIdx}>
                  <span className={s.childPanelSubHeader}>
                    #{capIdx + 1} CAP
                    <span style={{ color: 'white' }}>
                      {new Date(cap.dueDate).toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </span>
                  </span>
                  <hr />
                  <span className={s.actionDescription}>{cap.cap}</span>
                </div>)}
              </div>
            </div>)}
          </Panel>
        </Collapse >
      ))
    );
  };

  const renderClassificationCollapse = (classification) => {
    return (
      classification.map((classificationItem, classIdx) => (
        <Collapse
          key={"classificationItem" + classIdx}
          className={s.parentCollapse}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <img
              className={isActive ? `${s.arrowActive}` : `${s.arrow}`}
              alt="arrow"
              src="/img/icon/accordion-arrow-down.svg"
            />
          )}
        >
          <Panel
            header={
              <div className={s.panelHeader}>
                <div className={`${s.accordionHeader} ${s.badge} ${s['badge-' + classificationItem.classification?.toLowerCase()]}`}>{classificationItem.classification?.toUpperCase()}</div>
                <div className={s.statusCountContainer}>
                  {statusCount({
                    capVal: classificationItem.capCount,
                    findingVal: classificationItem.findingCount,
                    overdueVal: classificationItem.overdueCount
                  })}
                </div>
              </div>
            }
            key={"classificationItem" + classIdx}
          >
            <div className={activeKeys.includes("classificationItem" + classIdx) ? s.expandedContent : ''}>
              {renderCategoryCollapse(classificationItem.categories)}
            </div>
          </Panel>
        </Collapse>
      ))
    );
  };

  const renderAssuranceRefNumCollapse = (assuranceRefNumber) => {
    return (
      assuranceRefNumber.map((assuranceRefNumber, itemIdx) =>
        <Collapse
          key={"assuranceRefNumber" + itemIdx}
          className={s.parentCollapse}
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <img
              className={isActive ? `${s.arrowActive}` : `${s.arrow}`}
              alt="arrow"
              src="/img/icon/accordion-arrow-down.svg"
            />
          )}
        >
          <Panel
            header={
              <div className={s.panelHeader}>
                <div className={`${s.accordionHeader} ${s.assuranceReferenceNumber}`}>{assuranceRefNumber.assuranceReferenceNumber}</div>
              </div>
            }
            key={"assuranceRefNumber" + itemIdx}
          >
            <div className={activeKeys.includes("assuranceRefNumber" + itemIdx) ? s.expandedContent : ''}>
              {renderClassificationCollapse(assuranceRefNumber.classifications)}
            </div>
          </Panel>
        </Collapse>
      )
    );
  };
  

  const handlePanelChange = (keys) => {
    setActiveKeys(keys as string[]);
  };

  useEffect(() => {
    setActiveKeys([]);
  }, [assuranceReferenceNumberByYear]);
  

  return (
    <>
      {(assuranceReferenceNumberByYear.length === 0) ? (
        <div className={s.noResult}>
          <img src="/img/icon/no-result.svg" alt="No results" />
          <h2>No data available!</h2>
        </div>
      ) :
        assuranceReferenceNumberByYear.sort((a, b) => b.year - a.year).map((assuranceRefNumberByYear, classIdx) => 
          <Collapse
            key={"assuranceRefNumberByYear" + classIdx}
            className={s.parentCollapse}
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <img
                className={isActive ? `${s.arrowActive}` : `${s.arrow}`}
                alt="arrow"
                src="/img/icon/accordion-arrow-down.svg"
              />
            )}
            activeKey={activeKeys}
            onChange={(key) => {
              handlePanelChange(key)
            }}
          >
            <Panel
              header={
                <div className={s.panelHeader}>
                  <div className={`${s.accordionHeader}`}>{assuranceRefNumberByYear.year}</div>
                </div>
              }
              key={"assuranceRefNumberByYear" + classIdx}
            >
              <div className={activeKeys.includes("assuranceRefNumberByYear" + classIdx) ? s.expandedContent : ''}>
                {renderAssuranceRefNumCollapse(assuranceRefNumberByYear.assuranceReferenceNumber)}
              </div>
            </Panel>
          </Collapse>)
        }
    </>
  );
};

export default FindingCAPDetail;