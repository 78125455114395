import { USERMANAGEMENT_DOWNLOAD_FAILED } from './../action/actionType';
import { Action } from 'redux';
import { actionChannel, all, call, put, takeLatest } from 'redux-saga/effects';

import * as types from '../action/actionType';
import { STATUS } from '../constants/endpoints';
import { VIEWSCONFIGID } from '../constants/usermanagement.constanst';
import { transformAccessRoles } from '../factory/accessRoles.factory';
import { transformAddMapList } from '../factory/addMapList.factory';
import { transformUsersTable } from '../factory/usersTable.factory';
import { IUserPersona } from '../model/user.model';
import {
  createUser,
  deleteUser,
  getAddMapList,
  getOpus,
  getPersonaConfig,
  getPersonaList,
  getPersonas,
  getRoles,
  getUserManagement,
  getWMTable,
  updatePermission,
  updatePersonaList,
  updateUser,
  getUsersDownload,
  getUserStatus,
  rejectUser,
} from '../services/usermanagement.service';
import { getUserPersona, isSuperAdmin } from '../utils/user.utils';
interface UserManagementAction extends Action {
  data: {} | any;
}

function* fetchUserManagement(action: UserManagementAction) {
  const status = action?.data?.status
  const sortType = action?.data?.sortType
  try {
    const userPersona: IUserPersona = getUserPersona();
    const userOpuId =
      userPersona?.personaId && !isSuperAdmin() ? `/${userPersona?.opuId}` : "";
    const response = yield call(getUserManagement, userOpuId, status, sortType);
    const transformInitData = transformUsersTable(response.result);

    yield put({
      type: types.USERMANAGEMENT_GET_USERS_SUCCEEDED,
      payload: transformInitData,
    });
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_GET_USERS_FAILED,
      message: error.message,
    });
  }
}

function* fetchOpus() {
  try {
    const userPersona: IUserPersona = getUserPersona();
    const userOpuId =
      userPersona?.personaId && !isSuperAdmin() ? `/${userPersona?.opuId}` : "";
    const response = yield call(getOpus, userOpuId);

    yield put({
      type: types.USERMANAGEMENT_GET_OPUS_SUCCEEDED,
      payload: isSuperAdmin() ? response.result : [response.result],
    });
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_GET_OPUS_FAILED,
      message: error.message,
    });
  }
}

function* fetchRoles() {
  try {
    const response = yield call(getRoles);
    const transformData = transformAccessRoles(response.result);
    yield put({
      type: types.USERMANAGEMENT_GET_ROLES_SUCCEEDED,
      payload: transformData,
    });
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_GET_ROLES_FAILED,
      message: error.message,
    });
  }
}

function* fetchPersona() {
  try {
    const response = yield call(getPersonas);
    yield put({
      type: types.USERMANAGEMENT_GET_PERSONAS_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_GET_PERSONAS_FAILED,
      message: error.message,
    });
  }
}

interface UpdateUser extends Action {
  data: [] | any;
}

function* fetchUpdateUser(action: UpdateUser) {
  const {
    userId,
    accessId,
    enabled,
    personaId
  } = action.data;
  try {
    const updateData: any = {
      enabled: enabled,
      personaId: personaId,
      accessId: accessId,
    };
    const response = yield call(updateUser, userId, updateData);
    if (response.status === STATUS.UPDATE) {
      yield put({ type: types.USERMANAGEMENT_GET_USERS_REQUESTED });
      yield put({ type: types.USERMANAGEMENT_PUT_USER_SUCCEEDED });
    }
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_POST_USER_FAILED,
    });
  }
}

interface CreateUser extends Action {
  data: {} | any;
}

function* fetchCreateUser(action: CreateUser) {
  const { accessId,
    accessReason,
    email,
    enabled,
    name,
    personaId } = action.data;

  const createData = {
    name: name,
    email: email,
    personaId: personaId,
    reason: accessReason,
    accessRoleId: accessId,
    enabled: enabled
  };

  try {
    const response = yield call(createUser, createData);
    if (response.status === STATUS.CREATED) {
      yield put({ type: types.USERMANAGEMENT_GET_USERS_REQUESTED });
      yield put({ type: types.USERMANAGEMENT_POST_USER_SUCCEEDED });
    }
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_POST_USER_FAILED,
    });
  }
}

function* fetchPersonaWM() {
  try {
    const userPersona: IUserPersona = getUserPersona();
    const userOpuId =
      userPersona?.personaId && !isSuperAdmin()
        ? `/mapsconfig/${userPersona?.opuId}`
        : "/mapsconfig";
    const response = yield call(getWMTable, userOpuId);
    yield put({
      type: types.USERMANAGEMENT_PERSONA_WM_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_PERSONA_WM_FAILED,
      message: error.message,
    });
  }
}

function* fetchPersonaVC() {
  try {
    const userPersona: IUserPersona = getUserPersona();
    const userOpuId =
      userPersona?.personaId && !isSuperAdmin()
        ? `/viewsconfig/${userPersona?.opuId}`
        : "/viewsconfig";
    const response = yield call(
      getPersonaConfig,
      VIEWSCONFIGID.VALUECHAIN,
      userOpuId
    );
    yield put({
      type: types.USERMANAGEMENT_PERSONA_VC_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_PERSONA_VC_FAILED,
      message: error.message,
    });
  }
}

function* fetchPersonaSC() {
  try {
    const userPersona: IUserPersona = getUserPersona();
    const userOpuId =
      userPersona?.personaId && !isSuperAdmin()
        ? `/viewsconfig/${userPersona?.opuId}`
        : "/viewsconfig";
    const response = yield call(
      getPersonaConfig,
      VIEWSCONFIGID.SCORECARDS,
      userOpuId
    );
    yield put({
      type: types.USERMANAGEMENT_PERSONA_SC_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_PERSONA_SC_FAILED,
      message: error.message,
    });
  }
}

function* fetchPersonaMFT() {
  try {
    const userPersona: IUserPersona = getUserPersona();
    const userOpuId =
      userPersona?.personaId && !isSuperAdmin()
        ? `/viewsconfig/${userPersona?.opuId}`
        : "/viewsconfig";
    const response = yield call(
      getPersonaConfig,
      VIEWSCONFIGID.MFT,
      userOpuId
    );
    yield put({
      type: types.USERMANAGEMENT_PERSONA_MFT_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_PERSONA_MFT_FAILED,
      message: error.message,
    });
  }
}

function* fetchAddMapList() {
  try {
    const response = yield call(getAddMapList);
    const transformData = transformAddMapList(response.result);
    yield put({
      type: types.USERMANAGEMENT_ADD_MAP_LIST_SUCCEEDED,
      payload: transformData,
    });
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_ADD_MAP_LIST_FAILED,
      message: error.message,
    });
  }
}

interface UpdatePermission extends Action {
  data: {} | any;
}

function* fetchUpPer(action: UpdatePermission) {
  try {
    const response = yield call(updatePermission, action.data);
    if (response.status === STATUS.UPDATE) {
      yield put({
        type: types.USERMANAGEMENT_PERSONA_WM_REQUESTED,
      });
      yield put({
        type: types.USERMANAGEMENT_UPDATE_PERMISSION_SUCCEEDED,
      });
    }
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_UPDATE_PERMISSION_FAILED,
      message: error.message,
    });
  }
}

function* fetchPersonVCList() {
  try {
    const response = yield call(getPersonaList, VIEWSCONFIGID.VALUECHAIN);
    yield put({
      type: types.USERMANAGEMENT_PERSONVCLIST_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_PERSONVCLIST_FAILED,
      message: error.message,
    });
  }
}

function* fetchPersonSCList() {
  try {
    const response = yield call(getPersonaList, VIEWSCONFIGID.SCORECARDS);
    yield put({
      type: types.USERMANAGEMENT_PERSONSCLIST_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_PERSONSCLIST_FAILED,
      message: error.message,
    });
  }
}

function* fetchPersonMFTList() {
  try {
    const response = yield call(getPersonaList, VIEWSCONFIGID.MFT);
    yield put({
      type: types.USERMANAGEMENT_PERSONMFTLIST_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_PERSONMFTLIST_FAILED,
      message: error.message,
    });
  }
}

interface UpdatePersona extends Action {
  data: {} | any;
}

function* fetchUpdatePersona(action: UpdatePersona) {
  try {
    const { viewTypeId, personaId, updateData } = action.data;
    const response = yield call(
      updatePersonaList,
      viewTypeId,
      personaId,
      updateData
    );
    if (response.status === STATUS.UPDATE) {
      if (VIEWSCONFIGID.VALUECHAIN === viewTypeId) {
        yield put({
          type: types.USERMANAGEMENT_PERSONA_VC_REQUESTED,
          payload: response.result,
        });
      }
      else if (VIEWSCONFIGID.MFT === viewTypeId) {
        yield put({
          type: types.USERMANAGEMENT_PERSONA_MFT_REQUESTED,
          payload: response.result,
        });
      }
      else {
        yield put({
          type: types.USERMANAGEMENT_PERSONA_SC_REQUESTED,
          payload: response.result,
        });
      }
      yield put({
        type: types.USERMANAGEMENT_UPDT_PERSONA_SUCCEEDED,
        data: viewTypeId,
      });
    }
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_UPDT_PERSONA_FAILED,
      message: error.message,
    });
  }
}

function* fetchDelUser(action: UpdateUser) {
  try {
    const {
      userId
    } = action.data;
    const response = yield call(deleteUser, userId);
    if (response.status === STATUS.UPDATE) {
      yield put({ type: types.USERMANAGEMENT_GET_USERS_REQUESTED });
      yield put({ type: types.USERMANAGEMENT_DELETE_USER_SUCCEEDED });
    }
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_POST_USER_FAILED,
    });
  }
}

function* fetchRejectUser(action: UpdateUser) {
  try {
    const response = yield call(rejectUser, action.data);
    if (response.status === STATUS.UPDATE) {
      yield put({ type: types.USERMANAGEMENT_GET_USERS_REQUESTED });
      yield put({ type: types.USERMANAGEMENT_REJECT_USER_SUCCEEDED });
    }
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_REJECT_USER_FAILED,
    });
  }
}


function* fetchUserDownload() {
  try {
    const response = yield call(getUsersDownload);
    const { fileName, content } = response.result

    const mediaType="data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    const a = document.createElement('a');
    a.href = mediaType+content;
    a.download = fileName;
    a.click()

    yield put({
      type: types.USERMANAGEMENT_DOWNLOAD_SUCCEEDED,
    });
  } catch (error: any) {
    yield put({
      type: types.USERMANAGEMENT_DOWNLOAD_FAILED,
    });
  }
}

function* fetchUserStatus() {
  try {
    const response = yield call(getUserStatus);
    yield put({
      type: types.USERMANAGEMENT_GET_STATUS_SUCCEEDED,
      payload: response.result
    });
  } catch (error: any) {
  }
}

function* setActiveButton(action) {
  try {
    yield put(setActiveButton(action.data));
  } catch (error: any) {
  }
}

export function* workerUserManagementData() {
  yield all([
    takeLatest(types.USERMANAGEMENT_GET_USERS_REQUESTED, fetchUserManagement),
    takeLatest(types.USERMANAGEMENT_GET_OPUS_REQUESTED, fetchOpus),
    takeLatest(types.USERMANAGEMENT_GET_ROLES_REQUESTED, fetchRoles),
    takeLatest(types.USERMANAGEMENT_GET_PERSONAS_REQUESTED, fetchPersona),
    takeLatest(types.USERMANAGEMENT_PUT_USER_REQUESTED, fetchUpdateUser),
    takeLatest(types.USERMANAGEMENT_PERSONA_WM_REQUESTED, fetchPersonaWM),
    takeLatest(types.USERMANAGEMENT_PERSONA_VC_REQUESTED, fetchPersonaVC),
    takeLatest(types.USERMANAGEMENT_PERSONA_SC_REQUESTED, fetchPersonaSC),
    takeLatest(types.USERMANAGEMENT_PERSONA_MFT_REQUESTED, fetchPersonaMFT),
    takeLatest(types.USERMANAGEMENT_ADD_MAP_LIST_REQUESTED, fetchAddMapList),
    takeLatest(types.USERMANAGEMENT_UPDATE_PERMISSION_REQUESTED, fetchUpPer),
    takeLatest(types.USERMANAGEMENT_PERSONVCLIST_REQUESTED, fetchPersonVCList),
    takeLatest(types.USERMANAGEMENT_PERSONSCLIST_REQUESTED, fetchPersonSCList),
    takeLatest(types.USERMANAGEMENT_PERSONMFTLIST_REQUESTED, fetchPersonMFTList),
    takeLatest(types.USERMANAGEMENT_UPDT_PERSONA_REQUESTED, fetchUpdatePersona),
    takeLatest(types.USERMANAGEMENT_POST_USER_REQUESTED, fetchCreateUser),
    takeLatest(types.USERMANAGEMENT_DELETE_USER_REQUESTED, fetchDelUser),
    takeLatest(types.USERMANAGEMENT_REJECT_USER_REQUESTED, fetchRejectUser),
    takeLatest(types.USERMANAGEMENT_DOWNLOAD_REQUESTED, fetchUserDownload),
    takeLatest(types.USERMANAGEMENT_GET_STATUS, fetchUserStatus),
    takeLatest(types.SET_ACTIVE_BUTTON, setActiveButton),
  ]);
}
