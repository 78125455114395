import { isNull } from "lodash";

export function formatNumber(number: any) {
  if (isNull(number)) {
    return '--';
  }
  //return String(number).replace(/(.)(?=(\d{3})+$)/g, '$1,');
  return Number(number).toLocaleString("en-US");
}
export function kFormatter(num: number) {
  const x = ((Math.abs(num) / 1000).toFixed(1));
  return Math.abs(num) > 999 ? Math.sign(num) * Number(x) + 'k' : Math.sign(num) * Math.abs(num)
}

export function toFixedNumber(number: any, numberToFixed: number) {
  if (number === null || number === undefined) {
    return '-';
  }
  return Number(number) && Number(number) % 1 !== 0 ? 
    formatNumberThousand(Number(number).toFixed(numberToFixed), numberToFixed) : formatNumberThousand(Number(number), null);
}

export function toFixedValueChainNumber(number: any, numberToFixed: number) {
  if (number === null || number === undefined || number === 'N/A') {
    return '--';
  }
  return Number(number).toFixed(numberToFixed);
}

export function toFixedSustainabilityNumber(number: any, numberToFixed: number) {
  if (number === null || number === undefined) {
    return '--';
  }
  return Number(number).toFixed(numberToFixed);
}

export function toFixedSustainability(number: any, numberToFixed: number) {
  return Number(number).toFixed(numberToFixed);
}

export function toFixedNumberAbsolute(number: any, numberToFixed: number) {
  if (isNull(number)) {
    return '-';
  }
  return formatNumberThousand(Number(number).toFixed(numberToFixed), numberToFixed);
}

export function formatNumberThousand(number: any, decimalNumber: any) {
  //return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return Number(number).toLocaleString("en-US", {minimumFractionDigits: decimalNumber ?? 0, maximumFractionDigits: decimalNumber ?? 0});
}

export function formatScoreCardValue(number: any, currency: any, toFixedNumberValue: number) {
  const currencyFixValue = currency ? 1 : toFixedNumberValue;
  if (isNull(number) || number === undefined) {
    return '-';
  }

  return Number(number) && number <= 1000
          ? Number(number).toFixed(currencyFixValue)
          : formatNumberThousand(number, 0)
}

export function formatScoreCardTargetValue(number: any, toFixedNumberValue: number) {
  if (toFixedNumberValue === 0) {
    return isNull(number) || number === undefined ? "-" : Math.trunc(number);
  }
  return isNull(number) || number === undefined ? "-" : number.toFixed(toFixedNumberValue);
}

export function formatScoreCardValueAbsolute(number: any, currency: any, toFixedNumberValue: number) {
  const currencyFixValue = currency ? 1 : toFixedNumberValue;
  return isNull(number) ? "-" : toFixedNumberAbsolute(number, currencyFixValue);
}

export function formatScoreCardNumber(number: any, toFixedNumberValue: number) {
  return isNull(number) ? "-" : toFixedNumber(Math.abs(number), toFixedNumberValue);
}

export function formatScoreCardPercent(number: any, toFixedNumberValue: number) {
  return isNull(number) ? "0" : toFixedNumber(Math.abs(number), toFixedNumberValue);
}

export function tenKFormatter(num) {
  return Math.abs(num) > 9999 ? Math.sign(num) * (Math.round(Math.abs(num) / 100) / 10) + 'k' : Math.sign(num) * Math.abs(num)
}

export function isEvenNumber(n) {
  return n % 2 === 0;
}
